<div class="banner_area_type3 bnr_overlay2">
  <div class="holder">
    <div class="hr">
      <div class="vc">
        <div class="banner_section_title align-center">
          <div class="section_sub_title">
            <p>Booking</p>
          </div>
          <h1>Find Your Desire Room</h1>
        </div>
      </div>
    </div>
    <div class="hr">
      <div class="vc">
        <div class="bradecrumbe">
          <ul class="breadcrumb justify-content-center justify-content-cd--center">
            <li>
              <a routerLink='/'>
                Home
              </a>
            </li>
            <li class="active">
              Booking
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="main-content bkng">
  <div class="container">
    <div class="page-content">
      <div class="booking-tabs-list">
        <ul class="nav nav-tabs" id="myTab3" role="tablist">
          <li class="nav-item">
            <a class="nav-link"> <span class="nom"></span>CHOSE ROOM</a>
          </li>
          <li class="nav-item">
            <a class="nav-link"> <span class="nom"></span> BOOKING</a>
          </li>
          <li class="nav-item">
            <a class="nav-link active"> <span class="nom"></span> CHECKOUT</a>
          </li>
          <li class="nav-item">
            <a class="nav-link"><span class="nom"></span> THANK YOU</a>
          </li>
        </ul>
      </div>
      <!--booking-tabs-list end-->

      <div class="row">
        <div class="col-lg-4">
          <div class="sidebar">
            <div class="avail-sec bg">
              <form>
                <div class="checking-form">
                  <ul class="checkform">
                    <li>
                      <div class="check-form">
                        <h4>CHECK-IN</h4>
                        <div class="det">
                          <b class="val-date">{{ this.daySelected }}</b>
                          <span>
                            <i class="month">{{monthArray[monthSelected] }}</i> <i class="year">
                              {{  this.yearSelected  }}</i>
                            <a href="#" title="" class="arw-down"><i class="fa fa-angle-down"></i></a>
                          </span>
                        </div>
                        <input type="text" name="dateIn" class="date-pick" [(ngModel)]="booking.fromDate">
                        <div class="clearfix"></div>
                      </div>
                      <!--check-form end-->
                    </li>
                    <li>
                      <div class="check-form">
                        <h4>CHECK-OUT</h4>
                        <div class="det">
                          <b class="val-date">{{ this.daySelected2 }}</b>
                          <span>
                            <i class="month">{{ monthArray[monthSelected2] }}</i> <i
                              class="year">{{  this.yearSelected2  }}</i>
                            <a href="#" title="" class="arw-down"><i class="fa fa-angle-down"></i></a>
                          </span>
                        </div>
                        <input type="text" name="dateOut" class="date-pick" [(ngModel)]="booking.toDate">
                        <div class="clearfix"></div>
                      </div>
                      <!--check-form end-->
                    </li>
                    <!-- <li class="item-cont">
                      <div class="check-form">
                        <h4 class="text-center">Guest</h4>
                        <div class="det">
                          <div class="handle-counter" id="handleCounter">
                                            <ul>
                                                <li class="input-fieldd"><input type="text" value="1" data-num="1"></li>
                                                <li class="counter-control">
                                                  <button class="counter-minus btn" disabled=""><i class="fa fa-angle-up"></i></button>
                                                  <button class="counter-plus btn"><i class="fa fa-angle-down"></i></button>
                                                </li>
                                            </ul>
                                            <div class="clearfix"></div>
                                        </div>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                      <a href="#" title="" class="arw-down"><i class="fa fa-angle-down"></i></a>
                    </li> -->

                    <li class="item-cont">
                      <div class="check-form">
                        <div class="form-group">
                          <label for="no-of-guest" class="text-white">{{DiffDate}} night(s) stay</label>
                          <div data-visible="accommodation,flights" class="input-group guests">
                            <div data-component="index/guest-errors" data-id="main" data-view-id="accommodation"
                              role="alert">
                            </div>
                            <!-- <div class="input-box" (click)="toggleRoomsAndOccupancy()"> -->

                            <label class="text-default ">
                              <span class="guests_count">
                                <span data-adults-count="">{{booking.noOfPersons}} adults</span>
                                <span data-visible="accommodation">
                                  &nbsp;·&nbsp;
                                  <span data-children-count="">{{booking.noOfChildren}} child(s)</span>
                                </span>
                                <span data-visible="accommodation">
                                  &nbsp;·&nbsp;
                                  <span data-room-count="">{{booking.noOfRooms}} room</span>
                                </span>
                              </span>
                            </label>
                            <!-- </div> -->
                            <!-- <div class="list-holder roomsAndOccupancy" role="region"
                              aria-label="Rooms and occupancy" *ngIf="roomsAndOccupancy == true">
                              <div class="item business-text p-4">
                                 <div class="row">
                                    <div class="col-10">

                                    </div>
                                    <div class="col-2">
                                       <button class="btn btn-default btn-sm"
                                          (click)="toggleRoomsAndOccupancy()">X</button>
                                    </div>
                                 </div>
                                 <div class="clearfix" data-render="">
                                    <div class="group_field group_field-adults">

                                       <div class="row stepper" data-component="InputStepper">
                                          <div class="col-6 stepper_title-wrapper text-default">
                                             <label class="stepper_title"
                                                for="group_adults">Adults</label>
                                          </div>
                                          <div class="col-6">
                                             <input style="display: none" type="number"
                                                class="stepper_input" data-ref="input-stepper-field"
                                                id="group_adults" name="group_adults" min="1" max="30"
                                                value="2" data-group-adults-count=""
                                                [(ngModel)]="guest">
                                             <button class="btn btn-outline-primary btn-sm" type="button"
                                                (click)="guest = guest - 1; booking.noOfPersons = guest;"
                                                [disabled]="guest <=1">
                                                <span class="button_text">−</span>
                                             </button>
                                             <span class="stepper_display text-default"
                                                aria-hidden="true">{{booking.noOfPersons}}</span>
                                             <button class="btn btn-outline-primary btn-sm" type="button"
                                                (click)="guest = guest + 1; booking.noOfPersons = guest; planDetails"
                                                [disabled]="(maxOccupancy + noOfChildren) * noOfrooms <= guest">
                                                <span class="button_text">+</span>
                                             </button>
                                             <!-- <span class="stepper_display" aria-live="assertive" >{{adults}} Adults</span> -->
                            <!-- </div>
                                       </div>
                                    </div>
                                    <div class="group_field group-children ">
                                       <div class="row stepper" data-component="InputStepper">
                                          <div class="col-6 stepper_title-wrapper">
                                             <label class="stepper_title text-default"
                                                for="group_children">Children</label>
                                          </div>
                                          <div class="col-6">
                                             <input style="display: none" type="number"
                                                class="stepper_input" data-ref="input-stepper-field"
                                                id="group_adults" name="group_adults" min="1" max="30"
                                                value="2" data-group-adults-count="">
                                             <button class="btn btn-outline-primary btn-sm" type="button"
                                                (click)="noOfChildren = noOfChildren - 1; booking.noOfChildren = noOfChildren;"
                                                [disabled]="noOfChildren <=0">
                                                <span class="button_text">−</span>
                                             </button>
                                             <span class="stepper_display text-default"
                                                aria-hidden="true">{{booking.noOfChildren}}</span>
                                             <button class="btn btn-outline-primary btn-sm" type="button"
                                                (click)="noOfChildren = noOfChildren + 1; booking.noOfChildren = noOfChildren; "
                                                [disabled]="(maxOccupancy + noOfChildren)* noOfrooms <= noOfChildren">
                                                <span class="button_text">+</span>
                                             </button>
                                             <!-- <span class="stepper_display" aria-live="assertive" id="group_children_desc">{{children}} Children</span> -->
                            <!-- </div>
                                       </div>
                                    </div>
                                    <div class="group_field group_field-rooms">
                                       <div class="row stepper" data-component="InputStepper">
                                          <div class="col-6 stepper_title-wrapper">
                                             <label class="stepper_title text-default"
                                                for="no_rooms">Rooms</label>
                                          </div>
                                          <div class="col-6">
                                             <input style="display: none" type="number"
                                                class="stepper_input" data-ref="input-stepper-field"
                                                id="group_adults" name="group_adults" min="1" max="30"
                                                value="2" data-group-adults-count="">
                                             <button class="btn btn-outline-primary btn-sm" type="button"
                                                (click)="noOfrooms = noOfrooms - 1; booking.noOfRooms = noOfrooms;"
                                                [disabled]="noOfrooms <=1">
                                                <span class="button_text">−</span>
                                             </button>
                                             <span class="stepper_display text-default"
                                                aria-hidden="true">{{booking.noOfRooms}}</span>
                                             <button class="btn btn-outline-primary btn-sm" type="button"
                                                (click)="noOfrooms = noOfrooms + 1; booking.noOfRooms = noOfrooms;"
                                                [disabled]="maxSelectRoom <= noOfrooms">
                                                <span class="button_text">+</span>
                                             </button>

                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>  -->
                          </div>
                        </div>
                      </div>
                      <!--check-form end-->
                    </li>
                    <!-- <li class="check-avail">
                      <a (click)="getAvailableRoom()" title="" class="check-status">CHECK AVAILABILITY</a>
                    </li> -->
                  </ul>
                  <div class="clearfix"></div>
                </div>
              </form>
            </div>
            <!--avail-sec end-->
          </div>
          <!--sidebar end-->
        </div>
        <div class="col-lg-8">
          <mat-progress-bar *ngIf="loader" color="warn" mode="indeterminate"></mat-progress-bar>
          <div class="more_information_sec">
            <h3>Your InformatIons</h3>
            <table>
              <tr>
                <td>
                  <h4>First Name :</h4>
                  <span>{{ this.booking.firstName }}</span>
                </td>
                <td>
                  <h4>Last First :</h4>
                  <span>{{ this.booking.lastName }}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <h4>Phone :</h4>
                  <span>{{ this.booking.mobile }}</span>
                </td>
                <td>
                  <h4>Email :</h4>
                  <span>{{ this.booking.email }}</span>
                </td>
              </tr>
            </table>

            <div class="rq-info cv_lst">
              <h4>Room Information :</h4>
              <ul>
                <li><b>Room Name : </b> {{ booking.roomName }}</li>
                <li><b>No of rooms : </b> {{ booking.noOfRooms }}</li>
                <li><b>No of guest : </b> {{ booking.noOfPersons }}</li>
                <li><b>No of child(s) : </b> {{ booking.noOfChildren }}</li>
              </ul>
            </div>
            <div class="payment_options">
              <h4 class="mb-3 mt-4">Payment Method</h4>
              <div *ngIf="paymentLoader" class="progress">
                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                  aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
              </div>
              <div class="nav-wrapper">
                <ul class="nav nav-pills nav-fill flex-column flex-md-row" id="tabs-icons-text" role="tablist">
                  <!-- <li class="nav-item active " style="border: 1px solid #FFCF04;">
                    <a class="nav-link mb-sm-3 mb-md-0 text-dark" id="tabs-icons-text-2-tab" data-toggle="tab"
                      href="#tabs-icons-text-2" role="tab" target="_self" aria-controls="tabs-icons-text-2"
                      aria-selected="false"><i class="fa fa-money  mr-2 text-dark"(click)="cashOnDelivery()"></i>Pay While
                      Check-in</a>
                  </li> -->

                  <li class="nav-item  active" style="border: 1px solid #FFCF04;">
                    <a class="nav-link mb-sm-3 mb-md-0 text-dark" id="tabs-icons-text-1-tab" data-toggle="tab"
                      href="#tabs-icons-text-1" role="tab" aria-controls="tabs-icons-text-1" target="_self"
                      aria-selected="true" ><i class="fa fa-credit-card mr-2 text-dark"></i>Make Payment</a>
                  </li>

                  <!-- <li class="nav-item" *ngIf="property.bankAccount != undefined && property.bankAccount != null">
                    <a class="nav-link mb-sm-3 mb-md-0" id="tabs-icons-text-3-tab" data-toggle="tab"
                      href="#tabs-icons-text-3" role="tab" aria-controls="tabs-icons-text-3" target="_self"
                      aria-selected="false" (click)="bankPayment()"><i class="fa fa-  mr-2"></i>Bank
                      Transfer</a>
                  </li>

                  <li class="nav-item" *ngIf="property.mobileWallet != undefined && property.mobileWallet != null">
                    <a class="nav-link mb-sm-3 mb-md-0" id="tabs-icons-text-4-tab" data-toggle="tab"
                      href="#tabs-icons-text-4" role="tab" aria-controls="tabs-icons-text-4" target="_self"
                      aria-selected="false" (click)="MobileWallet()"><i class="fa fa-  mr-2"></i>Mobile Wallet</a>
                  </li> -->
                </ul>
              </div>
              <div class="card shadow ">
                <div class="card-body m-3">
                  <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade " id="tabs-icons-text-2" role="tabpanel"
                      aria-labelledby="tabs-icons-text-2-tab">
                      <form action="">
                        <div class="row">
                          <div class="col-md-12">
                            <h5>Confirm booking</h5>
                            <p>Pay the total amount
                              {{booking.totalAmount | currency: currency:'symbol':'1.2-2' }}
                              when you check-in at the hotel.</p>
                          </div>
                          <div class="col-md-12 mt-3">
                            <button class="common_btn btn_hbreffect2 text-dark" type="button" [disabled]=" paymentLoader"
                              (click)="onCashPaymentSubmit()">Confirm</button>

                          </div>
                        </div>
                      </form>
                    </div>
                    <div class="tab-pane fade show active " id="tabs-icons-text-1" role="tabpanel"
                      aria-labelledby="tabs-icons-text-1-tab">
                      <div class="row">
                        <div class="col-md-12">

                            <span style="margin-bottom: 50px;">Please pay 50% of the total booking amount </span>
                            <button  class="stripe"  (click)="payAndCheckout()">

                             Pay now
                            </button>

                        </div>
                      </div>
                      <!-- <div class="row"
                        *ngIf="!cashPayment && property.paymentGateway != 'eway' && property.paymentGateway != 'stripe'">
                        <div class="col-12">
                          <p class="result-message">Card payment not available!</p>
                        </div>
                      </div> -->
                    </div>

                    <div class="tab-pane fade" id="tabs-icons-text-3" role="tabpanel"
                      aria-labelledby="tabs-icons-text-3-tab">
                      <form action="" *ngIf="bankAccount != undefined">
                        <div class="row">
                          <div class="col-md-12">
                            <h5>Bank Information</h5>

                            <div class="row mb-4">

                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="streetNumberc">Bank Name</label>
                                  <input type="text" id="BankName" placeholder="Bank Name" readonly
                                    class="form-control form-control-alternative" [(ngModel)]="bankAccount.bankName"
                                    type="text" name="BankName" #BankName="ngModel">

                                </div>
                              </div>
                              <!-- streetName -->
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="Branch">Branch Name</label>
                                  <input type="text" id="Branch" placeholder="Branch Name"
                                    class="form-control form-control-alternative" readonly
                                    [(ngModel)]="bankAccount.branchName" type="text" name="Branch" #Branch="ngModel">

                                </div>
                              </div>

                            </div>

                            <div class="row mb-4">

                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="AccountName">Account Name</label>
                                  <input type="text" id="AccountName" placeholder="Account Name" readonly
                                    class="form-control form-control-alternative" [(ngModel)]="bankAccount.accountName"
                                    type="text" name="AccountName" #AccountName="ngModel">

                                </div>
                              </div>
                              <!-- streetName -->
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="Branch">Account Number</label>
                                  <input type="text" id="AccountNumber" placeholder="Account Number"
                                    class="form-control form-control-alternative" readonly
                                    [(ngModel)]="bankAccount.accountNumber" type="text" name="AccountNumber"
                                    #AccountNumber="ngModel">

                                </div>
                              </div>

                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="Branch">Swift Code</label>
                                  <input type="text" id="SwiftCode" placeholder="Swift Code"
                                    class="form-control form-control-alternative" readonly
                                    [(ngModel)]="bankAccount.swiftCode" type="text" name="SwiftCode"
                                    #SwiftCode="ngModel">

                                </div>
                              </div>

                            </div>

                            <p>Please pay {{ booking.totalAmount | number : '1.2-2' }} using
                              following bank details and share the transaction reference
                              number below.</p>

                            <div class="row mb-4">

                              <div class="col-md-12">
                                <div class="form-group">
                                  <label for="ReceiptNumber">Transaction Reference
                                    Number</label>
                                  <input type="text" id="ReceiptNumber" placeholder="Transaction Reference Number"
                                    class="form-control form-control-alternative" [(ngModel)]="payment.receiptNumber"
                                    type="text" name="ReceiptNumber" #ReceiptNumber="ngModel">

                                </div>
                              </div>
                            </div>

                          </div>
                          <div class="col-md-12">
                            <button class="common_btn btn_hbreffect2 text-dark" type="button"
                              [disabled]="paymentLoader || payment.receiptNumber === undefined || payment.receiptNumber === ''"
                              (click)="onBankPaymentSubmit()">Confirm</button>

                          </div>
                        </div>
                      </form>
                    </div>
                    <div class="tab-pane fade" id="tabs-icons-text-4" role="tabpanel"
                      aria-labelledby="tabs-icons-text-4-tab">
                      <form action="">
                        <div class="row" *ngIf="mobileWallet != undefined">
                          <div class="col-md-12">
                            <h5>Mobile Wallet Information</h5>

                            <div class="row mb-4">

                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="streetNumberc">First Name</label>
                                  <input type="text" id="FirstName" placeholder="First Name" readonly
                                    class="form-control form-control-alternative" [(ngModel)]="mobileWallet.firstName"
                                    type="text" name="FirstName" #FirstName="ngModel">

                                </div>
                              </div>
                              <!-- streetName -->
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="Branch">Last Name</label>
                                  <input type="text" id="LastName" placeholder="Last Name"
                                    class="form-control form-control-alternative" readonly
                                    [(ngModel)]="mobileWallet.lastName" type="text" name="LastName" #LastName="ngModel">

                                </div>
                              </div>

                              <div class="col-md-4">
                                <div class="form-group">
                                  <label for="Branch">Phone Number</label>
                                  <input type="text" id="PhoneNumber" placeholder="Phone Number"
                                    class="form-control form-control-alternative" readonly
                                    [(ngModel)]="mobileWallet.phoneNumber" type="text" name="PhoneNumber"
                                    #PhoneNumber="ngModel">

                                </div>
                              </div>

                            </div>

                            <div class="row mb-4">

                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="AccountName">Wallet Provider</label>
                                  <input type="text" id="WalletProvider" placeholder="Wallet Provider" readonly
                                    class="form-control form-control-alternative"
                                    [(ngModel)]="mobileWallet.walletProvider" type="text" name="WalletProvider"
                                    #WalletProvider="ngModel">

                                </div>
                              </div>
                              <!-- streetName -->
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="Branch">Wallet Url</label>
                                  <input type="text" id="WalletUrl" placeholder="Wallet Url"
                                    class="form-control form-control-alternative" readonly
                                    [(ngModel)]="mobileWallet.walletUrl" type="text" name="WalletUrl"
                                    #WalletUrl="ngModel">

                                </div>
                              </div>



                            </div>

                            <p>Please pay {{ booking.totalAmount | number : '1.2-2' }} using
                              following mobile wallet details and share the transaction
                              reference number below.</p>

                            <div class="row mb-4">

                              <div class="col-md-12">
                                <div class="form-group">
                                  <label for="ReceiptNumber">Transaction Reference
                                    Number</label>
                                  <input type="text" id="ReceiptNumber" placeholder="Transaction Reference Number"
                                    class="form-control form-control-alternative" [(ngModel)]="payment.receiptNumber"
                                    type="text" name="ReceiptNumber" #ReceiptNumber="ngModel">

                                </div>
                              </div>
                            </div>

                          </div>

                          <div class="col-md-12">
                            <button class="common_btn btn_hbreffect2 text-dark" type="button"
                              [disabled]=" paymentLoader || payment.receiptNumber === undefined || payment.receiptNumber === ''"
                              (click)="onWalletPaymentSubmit()">Confirm</button>

                          </div>
                        </div>
                      </form>
                    </div>
                    <!-- <form action="">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <input type="text" placeholder="Full Name"
                                  class="form-control form-control-alternative">
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-group">
                                <input type="text" placeholder="Bank Name"
                                  class="form-control form-control-alternative">
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-group">
                                <input type="text" placeholder="A/C Number"
                                  class="form-control form-control-alternative">
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <input type="text" placeholder="A/C Type"
                                  class="form-control form-control-alternative">
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <input type="text" placeholder="Swift Code"
                                  class="form-control form-control-alternative">
                              </div>
                            </div>
                            <div class="col-md-12">
                              <button class="btn btn-primary" type="button"
                                [disabled]="!orderForm.form.valid  || loader"
                                (click)="onCashPaymentSubmit(content)">Click n Collect</button>
                            </div>
                          </div>
                        </form>
                      </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--main-content end-->
