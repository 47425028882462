import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ApiService, PROPERTY_ID } from 'src/app/api.service';
import { HotelBookingService } from 'src/app/hotel-booking.service';
import { PropertyServiceDTO } from 'src/app/model/PropertyServices';
import { Booking } from 'src/app/model/booking';
import { DateModel } from 'src/app/model/dateModel';
import { Payment } from 'src/app/model/payment';
import { Room } from 'src/app/model/room';
import { BusinessUser } from 'src/app/model/user';
import { TokenStorage } from 'src/app/token.storage';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.css']
})
export class BookingComponent implements OnInit {
  headerTitle: string;
  bodyMessage: string;
  rooms: Room[];
  dateModel: DateModel;
  cardPaymentAvailable: boolean;
  daySelected: string;
  totalBeforeTaxAmount: number = 0;
  yearSelected: string;
  contentDialog: any;
  monthSelected: number;
  businessUser: BusinessUser;
  totalTaxAmount: number = 0;
  booking: Booking;
  paymentLoader: boolean = false;
  currentDay: string;
  day: string;
  isSuccess: boolean;
  year: string;
  month: number;
  addServiceList: PropertyServiceDTO[];
  payment: Payment;
  day2: string;
  year2: string;
  showAlert: boolean = false;
  alertType: string;
  month2: number;
  totalExtraAmount: number = 0;
  monthArray =['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];

   fromDate: string;
   toDate: string;
  constructor(
    private apiService: ApiService,
    private acRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private hotelBookingService: HotelBookingService,
    private token: TokenStorage,
    private changeDetectorRefs: ChangeDetectorRef,
    private router: Router) {
      this.addServiceList = [];
    this.dateModel = new DateModel();
    this.businessUser = new BusinessUser();
    this.payment = new Payment();
  }

 ngOnInit() {
    this.checkincheckOutDate();
    this.getRoom();

    this.acRoute.queryParams.subscribe(params => {

      if(params["dateob"] != undefined)
      {
          this.dateModel = JSON.parse(params["dateob"]);
          this.getRoomByDate( this.dateModel.checkIn  ,this.dateModel.checkOut  );
          this.getCheckInDateFormat(this.dateModel.checkIn);

      }

    });

  }
  checkincheckOutDate()
  {
    let currentDate: Date = new Date();
    this.day = this.getDay(currentDate);
    this.year = String(currentDate.getFullYear());
    this.month = currentDate.getMonth();


    let afterDate: Date = new Date();
    afterDate.setDate(currentDate.getDate()+1);

    this.day2 = this.getDay(afterDate);
    this.year2 = String(afterDate.getFullYear());
    this.month2 = afterDate.getMonth();
  }


  getDay(date: Date)
  {
    if(date.getDate().toString().length==1)
    {
        this.currentDay = '0'+date.getDate();
    }
    else
    {
        this.currentDay = ''+date.getDate();
    }

    return this.currentDay;
  }




  getRoom()
  {
    this.apiService.getRoomDetailsByPropertyId(PROPERTY_ID).subscribe(response => {

     // console.log('response room ' + JSON.stringify(response.body));
      this.rooms = response.body;
    },
      error => {
        if (error instanceof HttpErrorResponse) {

        }
      }
  );
  }
  payAndCheckout() {

    this.payment.callbackUrl = environment.callbackUrl + this.booking.propertyReservationNumber + "&BookingEngine=true";

    if (this.businessUser.paymentGateway === "paytm") {
      this.payment.paymentMode = "UPI";
      this.payment.status = "NotPaid";
      this.payment.businessServiceName = "Accommodation";
      this.payment.firstName = this.booking.firstName;
      this.payment.lastName = this.booking.lastName;
      this.payment.name = this.booking.firstName + " " + this.booking.lastName;

      this.payment.email = this.booking.email;
      this.payment.businessEmail = this.businessUser.email;
      this.payment.currency = this.businessUser.localCurrency;
      this.payment.propertyId = this.businessUser.id;
      this.booking.taxAmount = ((this.booking.netAmount * this.booking.taxPercentage) / 100);
      this.payment.taxAmount = Number((Number(((this.booking.taxAmount / 100) * 50).toFixed(2)) + Number(((this.totalTaxAmount / 100) * 50).toFixed(2))).toFixed(2));
      this.payment.netReceivableAmount = Number((Number(((this.booking.netAmount / 100)* 50).toFixed(2)) + Number(((this.totalBeforeTaxAmount  / 100) * 50).toFixed(2))).toFixed(2));
      this.payment.transactionAmount = Number((Number(((this.booking.totalAmount / 100) * 50).toFixed(2))));
      this.payment.amount = Number((Number(((this.booking.totalAmount / 100) * 50).toFixed(2))));
      this.booking.advanceAmount = Number((Number(((this.booking.totalAmount / 100) * 50).toFixed(2))));
      this.payment.transactionChargeAmount = Number((Number(((this.booking.totalAmount / 100) * 50).toFixed(2))));
      this.payment.referenceNumber = new Date().getTime().toString();
      this.payment.deliveryChargeAmount = 0;
      this.payment.date = this.datePipe.transform( new Date().getTime(), "yyyy-MM-dd" );
      // Logger.log("this.payment "+ JSON.stringify(this.payment));
      // this.token.saveBookingData(this.booking);
      // this.token.savePaymentData(this.payment);

      this.payment.callbackUrl = environment.callbackUrl;

      // this.processPaymentPayTM(this.payment);
      // this.processPaymentPayTM(this.payment);

      this.cardPaymentAvailable = true;
    }
    else if (this.businessUser.paymentGateway === "atom") {
      this.payment.paymentMode = "UPI";
      this.payment.status = "NotPaid";
      this.payment.businessServiceName = "Accommodation";
      this.payment.firstName = this.booking.firstName;
      this.payment.lastName = this.booking.lastName;
      this.payment.name = this.booking.firstName + " " + this.booking.lastName;

      this.payment.email = this.booking.email;
      this.payment.businessEmail = this.businessUser.email;
      this.payment.currency = this.businessUser.localCurrency;
      this.payment.propertyId = this.businessUser.id;
      this.booking.taxAmount = ((this.booking.netAmount * this.booking.taxPercentage) / 100);
      this.payment.taxAmount = Number((Number(((this.booking.taxAmount / 100) * 20).toFixed(2)) + Number(((this.totalTaxAmount / 100) * 20).toFixed(2))).toFixed(2));
      this.payment.netReceivableAmount = Number((Number(((this.booking.netAmount / 100)* 20).toFixed(2)) + Number(((this.totalBeforeTaxAmount  / 100) * 20).toFixed(2))).toFixed(2));
      this.payment.transactionAmount = Number((Number(((this.booking.totalAmount / 100) * 20).toFixed(2)) + Number(( (this.totalExtraAmount / 100) * 20).toFixed(2))).toFixed(2));
      this.payment.amount = Number((Number(((this.booking.totalAmount / 100) * 20).toFixed(2)) + Number(( (this.totalExtraAmount  / 100) * 20).toFixed(2))).toFixed(2));
      this.booking.advanceAmount = Number((Number(((this.booking.totalAmount / 100) * 20).toFixed(2)) + Number(((this.totalExtraAmount / 100) * 20).toFixed(2))).toFixed(2));
      this.payment.transactionChargeAmount = Number((Number(((this.booking.totalAmount / 100) * 20).toFixed(2)) + Number(((this.totalExtraAmount /100) * 20).toFixed(2))).toFixed(2));
      this.payment.referenceNumber = new Date().getTime().toString();
      this.payment.deliveryChargeAmount = 0;
      this.payment.date = this.datePipe.transform(
        new Date().getTime(),
        "yyyy-MM-dd"
      );
      // Logger.log("this.payment " + JSON.stringify(this.payment));
      // this.token.saveBookingData(this.booking);
      // this.token.savePaymentData(this.payment);

      // this.createBookingAtom();
      // this.processPaymentAtom(this.payment);

      this.cardPaymentAvailable = true;
    }
    else if (this.businessUser.paymentGateway === "hdfc") {
      this.payment.paymentMode = "UPI";
      this.payment.status = "NotPaid";
      this.payment.businessServiceName = "Accommodation";
      this.payment.firstName = this.booking.firstName;
      this.payment.lastName = this.booking.lastName;
      this.payment.name = this.booking.firstName + " " + this.booking.lastName;

      this.payment.email = this.booking.email;
      this.payment.businessEmail = this.businessUser.email;
      this.payment.currency = this.businessUser.localCurrency;
      this.payment.propertyId = this.businessUser.id;
      this.booking.taxAmount = ((this.booking.netAmount * this.booking.taxPercentage) / 100);
      this.payment.taxAmount = Number((Number(((this.booking.taxAmount / 100) * 50).toFixed(2)) + Number(((this.totalTaxAmount / 100) * 50).toFixed(2))).toFixed(2));
      this.payment.netReceivableAmount = Number((Number(((this.booking.netAmount / 100)* 50).toFixed(2)) + Number(((this.totalBeforeTaxAmount  / 100) * 50).toFixed(2))).toFixed(2));
      this.payment.transactionAmount = Number((Number(((this.booking.totalAmount / 100) * 50).toFixed(2))));
      this.payment.amount = Number((Number(((this.booking.totalAmount / 100) * 50).toFixed(2))));
      this.booking.advanceAmount = Number((Number(((this.booking.totalAmount / 100) * 50).toFixed(2)) + Number(((this.totalExtraAmount / 100) * 50).toFixed(2))).toFixed(2));
      this.payment.transactionChargeAmount = Number((Number(((this.booking.totalAmount / 100) * 50).toFixed(2)) + Number(((this.totalExtraAmount /100) * 50).toFixed(2))).toFixed(2));
      this.payment.referenceNumber = new Date().getTime().toString();
      this.payment.deliveryChargeAmount = 0;
      this.payment.date = this.datePipe.transform(
        new Date().getTime(),
        "yyyy-MM-dd"
      );
      // Logger.log("this.payment " + JSON.stringify(this.payment));
      // this.token.saveBookingData(this.booking);
      // this.token.savePaymentData(this.payment);

      // this.createBookingAtom();
      this.processPaymentHDFC(this.payment);

      this.cardPaymentAvailable = true;
    }
  }
  processPaymentHDFC(payment: Payment) {
    this.paymentLoader = true;
    this.changeDetectorRefs.detectChanges();

    this.hotelBookingService.processPayment(payment).subscribe(
      (response) => {
        if (response.status === 200) {
          if (response.body.failureMessage !== null) {
            this.paymentLoader = false;
            this.isSuccess = false;
            this.headerTitle = "Error!";
            this.bodyMessage =
              "Unable to process payment" +
              " Code: " +
              response.body.failureMessage;
            this.showDanger(this.contentDialog);

            this.changeDetectorRefs.detectChanges();
          } else {
            this.paymentLoader = false;
            this.payment = response.body;

            //for post booking create

            this.paymentIntentHdfc(this.payment);

            // for pre booking create

            this.addServiceToBooking(this.booking);
          }
        } else {
          this.paymentLoader = false;
          this.isSuccess = false;
          this.headerTitle = "Error!";
          this.bodyMessage = "Payment Failed! Code: " + response.status;
          this.showDanger(this.contentDialog);
          this.changeDetectorRefs.detectChanges();
        }
      },
      (error) => {
        this.paymentLoader = false;
        this.isSuccess = false;
        this.headerTitle = "Error!";
        this.bodyMessage = "Payment Failed! Code: " + error.status;
        this.showDanger(this.contentDialog);
        this.changeDetectorRefs.detectChanges();
      }
    );
  }

  paymentIntentHdfc(payment: Payment) {
    this.paymentLoader = true;

    this.hotelBookingService.paymentIntentHdfc(payment).subscribe((response) => {
      this.paymentLoader = false;
      if (response.status === 200) {
        this.payment = response.body;

        this.token.saveBookingData(this.booking);
        this.token.savePaymentData(this.payment);
        this.token.savePropertyData(this.businessUser);

        this.router.navigate(["/checkout-hdfc"]);
      }
    });
  }
  addServiceToBooking(booking) {
    if (this.addServiceList.length > 0) {
      this.hotelBookingService
        .addServicesToBooking(this.addServiceList, booking.id)
        .subscribe((serviceRes) => {
          // Logger.log("addServiceList ", JSON.stringify(serviceRes.body));
        });
    }
  }
  showDanger(content) {
    this.alertType = "danger";
    this.showAlert = true;
    setTimeout(() => {
      this.showAlert = false;
      this.changeDetectorRefs.detectChanges();
    }, 3000);
  }
getRoomByDate( fromDate: string ,toDate: string ) {
  this.apiService.getRoomDetailsByPropertyIdAndDate(PROPERTY_ID, fromDate, toDate) .subscribe(response => {

   // console.log('getRoomByDate ' + JSON.stringify(response.body));
    this.rooms = response.body;
  },
    error => {
      if (error instanceof HttpErrorResponse) {

      }
    }
);
}

  getCheckInDateFormat(dateString: string)
  {
    var yearAndMonth = dateString.split("-", 3);
    this.daySelected = String(yearAndMonth[2].split(" ", 1));
    this.yearSelected = yearAndMonth[0];
    this.monthSelected = parseInt(yearAndMonth[1])-1;
  }

  onRoomBooking(room)
  {

    this.dateModel.room = room;

    let navigationExtras: NavigationExtras = {
      queryParams: {
          dateob: JSON.stringify(this.dateModel),
      }
    };
    this.router.navigate(['/booking/booking'],navigationExtras );
  }

}
