import { NgModule } from '@angular/core';
import { HomeComponent } from './home/home.component';
import { Routes, RouterModule } from '@angular/router';
import { ServicesComponent } from './services/services.component';
import { AllRoomsComponent } from './all-rooms/all-rooms.component';
import { GalleryComponent } from './gallery/gallery.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ContactComponent } from './contact/contact.component';
import { BookingModule } from '../site/booking/booking.module';
import { PaymentComponent } from './payment/payment.component';
import { ServiceHomeComponent } from './service-home/service-home.component';
import { TermsComponent } from './terms/terms.component';
import { PriveryPolicyComponent } from './privery-policy/privery-policy.component';
import { RefundPolicyComponent } from './refund-policy/refund-policy.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: "all-rooms", component: AllRoomsComponent },
  { path: "gallery", component: GalleryComponent },
  { path: "services", component: ServicesComponent },
  { path: "aboutus", component: AboutusComponent },
  { path: "contact", component: ContactComponent },
  { path: "terms-conditions", component: TermsComponent },
  { path: "privacy-policy", component: PriveryPolicyComponent },
  { path: "refund-policy", component: RefundPolicyComponent },
  { path: 'booking', component: BookingModule },
  { path: "payment", component: PaymentComponent },
  { path: "service-home", component: ServiceHomeComponent }
];

@NgModule({
  imports: [


  RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
