import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-near-places',
  templateUrl: './near-places.component.html',
  styleUrls: ['./near-places.component.scss']
})
export class NearPlacesComponent implements OnInit {
  reveals: any;
  windowHeight: any;
  elementTop: any;
  elementVisible: any;
data = [
{
  name:"subha",
  url:"assets/images/visit1.jpeg"
},
{
  name:"subha",
  url:"assets/images/visit2.jpeg"
},
{
  name:"subha",
  url:"assets/images/visit3.jpeg"
},
{
  name:"subha",
  url:"assets/images/visit4.jpeg"
},

]
dataone = [
  {
    name:"subha",
    url:"assets/images/aboutimage3.png"
  },
  {
    name:"subha",
    url:"assets/images/aboutimage3.png"
  },
  {
    name:"subha",
    url:"assets/images/aboutimage3.png"
  }
  ]
  constructor() { }

  ngOnInit(): void {
    this.reveal()
  }


  reveal() {
    this.reveals = document.querySelectorAll('.reveal');

    for (var i = 0; i < this.reveals.length; i++) {
      this.windowHeight = window.innerHeight;
      this.elementTop = this.reveals[i].getBoundingClientRect().top;
      this.elementVisible = 150;

      if (this.elementTop < this.windowHeight - this.elementVisible) {
        this.reveals[i].classList.add('active');
      } else {
        this.reveals[i].classList.remove('active');
      }
    }
    window.addEventListener('scroll', this.reveal);
  }
  slideConfig = {
    centerMode: true,
    centerPadding: '20%',
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 1367,
        settings: {
          centerPadding: '15%',
        },
      },
      {
        breakpoint: 1025,
        settings: {
          centerPadding: '0',
        },
      },
      {
        breakpoint: 767,
        settings: {
          centerPadding: '0',
          slidesToShow: 1,
        },
      },
    ],
  };

}
