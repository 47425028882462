<div class="cmn_banner_area banner_type7 bnr_overlay2">
  <div class="holder">
    <div class="hr">
      <div class="vc">

        <div class="section_title align-center">
          <div class="section_sub_title ">
            <p class="text-center">hotel service</p>
          </div>
        </div>
      </div>
    </div>
    <div class="hr">
      <div class="vc">
        <div class="bradecrumbe">
          <ul class="breadcrumb justify-content-center justify-content-cd--center">
            <li>
              <a routerLink="/home">
                Home
              </a>
            </li>
            <li class="active">
              Hotel Service
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>



<div class="holder pt-5">
  <div class="row">
    <div class="col-md-6 mb-2">
      <img src="assets/images/homeAbout.png" alt="">
    </div>

    <div class="col-md-6 mb-5">
      <div class="overview_text_inner align-center">
        <div class="section_title align-center">

          <h1>Hotel A One Luxury  Puri,Odisha </h1>
        </div>
        <div class="section_body">
          <p>We appreciate you staying at our hotel when you were in India. We hope you liked
            your time with us and everything that our hotel has to offer. We value the time
            you took away from your hectic schedule to stay with us.
            Below are the specifics of our rooms' layout and property:<br>
            <strong>2 Rooms :</strong> The Chhota Parivar Room has amenities including WiFi, an air conditioner, geysers,
            and 24-hour room service and can house up to 3 people.<br><strong>
              1 Room :</strong>The Bada Paribar Room has amenities including WiFi, an air conditioner, geysers,
              and 24-hour room service and can accommodate up to 5 people.<br>
            <strong>4 Rooms :</strong>  The Parivar Room has amenities including WiFi, an air conditioner, geysers,
            and 24-hour room service and can accommodate up to 3 people.<br>
            <strong>6 Rooms :</strong>  The Nav Dampati Room has amenities including WiFi, an air conditioner, geysers,
            and 24-hour room service and can accommodate up to 2 people.<br>
            <strong>3 Rooms :</strong>  The Paribar Sea View Room has amenities including WiFi, an air
            conditioner, geysers, and 24-hour room service and can accommodate up to 3
            people.<br>
            <!-- <br><strong> Dormitory :</strong> <br>
            Can accommodate 4 pax on bed and 2 extra mattress can be accommodated has 2 washrooms . AC, Television set
            is not available in the dormitory .
            <br><strong>Restaurant :</strong><br> We have a restaurant serving vegetarian and Non vegetarian food the
            restaurant has a seating capacity of 40 to 50 persons , Nescafe vending machine available .
          </p> -->
        </div>
      </div>
    </div>


  </div>
  <div class="row">
    <div class="col-md-6 mb-5">
      <div class="overview_text_inner align-center">
        <div class="section_title align-center">

          <h1>Group Bookings</h1>
        </div>
        <div class="section_body">
          <p>We have 16 rooms where 2 Rooms are  Chhota Parivar and  1 Room is Bada Paribar Room and 4 Rooms are Parivar Room and 6 Rooms  are Nav Dampati Room
            and 3 Rooms are  Paribar Sea View Room <br>
            <!-- Rate for groups Bed and Breakfast : <br> -->
            <!-- <strong> Group Rates : </strong>
            1,500/- plus tax ( 12% gst ) per person per day includes stay and breakfast .<br>
            <strong>Vegetarian Menu Package :</strong> 1,000/- plus tax ( 10 %)<br>
            <strong>Non Vegetarian Meal Package :</strong> 1,200/- plus tax ( 10%) Includes non veg only in dinner .<br>
            <strong>Non Vegetarian Meal Package :</strong> 1,500/- plus taxes ( 10%) <br>
            Includes non veg in both meals .</p> <br> -->


        </div>
      </div>
    </div>

    <div class="col-md-6 mb-2">
      <img src="assets/images/homeAbout1.png" alt="">
    </div>
  </div>

  <div class="service_area_type2 ptb-95-70 pt-5">
    <div class="holder">
      <div class="service_inner">
        <div class="hr">
          <div class="vc-sd-6 vc-od-3">
            <div class="single_service  align-center mb-30">
              <i class="fa fa-cutlery" aria-hidden="true"></i>
              <h3>Restaurant</h3>
              <!-- <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh </p> -->

            </div>
          </div>
          <div class="vc-sd-6 vc-od-3">
            <div class="single_service  align-center mb-30">
              <i class="fa fa-wifi"></i>
              <h3>wi-fi</h3>
              <!-- <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh </p> -->

            </div>
          </div>
          <div class="vc-sd-6 vc-od-3">
            <div class="single_service align-center mb-30">
              <i class="fa fa-bus" aria-hidden="true"></i>
              <h3>Pick Up and Drop</h3>
              <!-- <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh </p> -->

            </div>
          </div>
          <div class="vc-sd-6 vc-od-3">
            <div class="single_service  align-center mb-30">
              <i class="fa fa-bed" aria-hidden="true"></i>
              <h3>Room Service</h3>
              <!-- <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh </p> -->

            </div>
          </div>
        </div>
        <div class="hr">

          <div class="vc-sd-6 vc-od-3">
            <div class="single_service align-center mb-30">
              <i class="fa fa-television" aria-hidden="true"></i>
              <h3>LED TV</h3>
              <!-- <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh </p> -->

            </div>
          </div>
          <div class="vc-sd-6 vc-od-3">
            <div class="single_service  align-center mb-30">
              <i class="fa fa-camera" aria-hidden="true"></i>
              <h3>Security</h3>
              <!-- <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh </p> -->

            </div>
          </div>
          <div class="vc-sd-6 vc-od-3">
            <div class="single_service  align-center mb-30">
              <i class="fa fa-car" aria-hidden="true"></i>
              <h3>Car parking</h3>
              <!-- <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh </p> -->

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
