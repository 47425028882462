<div class="hotel_service_type1 pt-5">
  <!-- Info Section -->
  <div class="holder">
    <div class="hr">
      <div class="vc">
        <div class="section_title align-center">
          <div class="section_sub_title">
            <h1>{{title}}</h1>
          </div>
          <p>{{desc}}</p>
        </div>
      </div>
    </div>
  </div>
  <!-- Info Section / End -->
  <!-- Categories Carousel -->
  <div class="hr">
    <div class="vc-sd-12">
      <div class="hotel_room_inner revealLeft wow fadeInLeft animated" style="visibility: visible; animation-name: fadeInLeft;">
        <!-- <div class="room-carousel-type1 owl-carousel"> -->
            <ngx-slick-carousel class="testimonial-carousel testimonials camera_wrap carousel listing-slider mfp-gallery-container margin-bottom-0 hero_slider" #slickModal="slick-carousel" [config]="slideConfig">
              <div ngxSlickItem *ngFor="let testimonial of data" class="slide fw-carousel-review">
                <div class="testimonial-box">
                  <div class="testimonial">{{testimonial.message}}</div>
                </div>
                <div class="testimonial-author">
                  <img src="{{testimonial.image}}" alt="">
                  <h4>{{testimonial.name}} <span>{{testimonial.position}}</span></h4>
                </div>
              </div>
            </ngx-slick-carousel>
        <!-- </div> -->
      </div>
    </div>
    <!-- Categories Carousel / End -->
  </div>
</div>

<div class="hotel_service_type1 secondary-bg-color pt-5">
  <div class="holder">
    <div class="hr">
      <div class="vc">
        <div class="section_title align-center">
          <div class="section_sub_title">
            <p>Hotel A One Luxury</p>
          </div>
          <h1>hotel service</h1>
        </div>
      </div>
    </div>
  </div>

  <div class="hotel_service_inner">
    <div class="single_hotel_service" *ngFor="let item of galleryItems">
      <img src="{{item.imageUrl}}" alt="">
      <div class="image_details">
        <h3>{{item.title}}</h3>
        <!-- <p>starting $ 120</p> -->
      </div>
    </div>
  </div>
</div>
