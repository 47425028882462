<!-- <ngx-slick-carousel class="carousel listing-slider mfp-gallery-container margin-bottom-0"
#slickModal="slick-carousel" >
<div ngxSlickItem *ngFor="let gallery of slideImage" class="slide">
  <img class="d-block w-100" height="650px" src="{{gallery.image}}" alt="First slide">

</div>
</ngx-slick-carousel> -->

<div class="banner_area_type1 banner_area overlapping-fix slider1">
  <div class="slider_overlay_area slider_height">
    <div class="holder slider_height">
      <div class="hr slider_height">
        <div class="vc-cd-12 vc-rd-8 vc-rd-offset-2 vc-sd-12 vc-ad-8 vc-ad-offset-2 slider_height vc-ad-">
          <div class="slider_inner_area slider_height">
            <div class="slider_inner_content align-center">
              <div class="section_title align-center">
                <div class="section_sub_title">
                  <p>Hotel A One Luxury</p>
                </div>
                <h1>Best memories starts here</h1>
              </div>
              <div class="bnr_check_room_area">
                <form #searchForm="ngForm" (ngSubmit)="submitForm(searchForm)" role="form">
                  <div class="hr">
                    <div class="vc-cd-12  vc-rd-12  vc-sd-3 vc-od-3">
                      <div class="cmn_field">
                        <label for="check1">
                          <i class="fa fa-calendar"></i>
                        </label>
                        <input class="field-control" name="checkIn" placeholder="Check In" [formControl]="CheckIn"
                          [(ngModel)]="checkIn" (click)="d1.toggle()" ngbDatepicker #d1="ngbDatepicker"
                          [placement]="placement" autocomplete="off">
                      </div>
                    </div>
                    <div class="vc-cd-12 vc-rd-12 vc-sd-3 vc-od-3">
                      <div class="cmn_field">
                        <label for="check2">
                          <i class="fa fa-calendar"></i>
                        </label>
                        <input class="field-control" name="checkOut" [formControl]="CheckOut" placeholder="Check Out"
                          [(ngModel)]="checkOut" [minDate]="checkIn" (click)="d2.toggle()" ngbDatepicker
                          #d2="ngbDatepicker" [placement]="placement" autocomplete="off">
                      </div>
                    </div>
                    <div class="vc-cd-12  vc-rd-12  vc-sd-3 vc-od-3">
                      <div class="row">
                        <div class="col-4">
                          <div class="cmn_field">
                            <label for="check3">
                              <i class="fa fa-user"></i>
                            </label>
                            <input class="field-control" id="check3" [formControl]="Guest" name="guest" type="number" placeholder="Guest"
                               [(ngModel)]="guest" max="{{maxOccupancy}}" min="1">
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="cmn_field">
                            <label for="check4">
                              <i class="fa fa-child"></i>
                            </label>
                            <input class="field-control style_check" id="check4" name="noOfChildren" type="number"
                              placeholder="Child" [formControl]="NoOfChildren" [(ngModel)]="noOfChildren">
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="cmn_field">
                            <label for="check5">
                              <i class="fa fa-hotel"></i>
                            </label>
                            <input type="number" class="field-control btn_3 style_check" id="check5" name="noOfRooms"
                              placeholder="Room" [formControl]="NoOfRooms" [(ngModel)]="noOfRooms"
                              [max]="maxSelectedRooms" min="1">
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="vc-cd-12 vc-rd-12 vc-sd-12 vc-od-3">
                      <button (click)="onBook()" class="common_btn2 btn_hbreffect1" style="width: 100%;">
                        Check
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <ngx-slick-carousel class="camera_wrap carousel listing-slider mfp-gallery-container margin-bottom-0 hero_slider"
    #slickModal="slick-carousel" [config]="slideConfig">
    <div ngxSlickItem *ngFor="let gallery of slideImage" class="slide camera_src camerastarted ">
      <img class="img_style" src="{{gallery.imageUrl}}" alt="First slide">
      <a [ngStyle]="{'background-image': 'url(' + gallery.imageUrl + ')'}" style="background-size: cover;"
        class="item mfp-gallery cover" title="{{gallery.title}}"></a>
    </div>
  </ngx-slick-carousel>

  <!-- <div class="camera_wrap " id="banner_slider1">
    <div class="hero_slider" *ngFor="let gallery of slideImage" data-src="{{gallery.imageUrl}}"></div>


  </div>-->
</div>

<!-- <ngx-slick-carousel class="carousel listing-slider mfp-gallery-container margin-bottom-0 hero_slider"
#slickModal="slick-carousel" [config]="slideConfig">
<div ngxSlickItem *ngFor="let gallery of slideImage" class="slide">
  <img class="d-block w-100" height="650px" src="{{gallery.imageUrl}}" alt="First slide">
  <a [ngStyle]="{'background-image': 'url(' + gallery.imageUrl + ')'}" style="background-size: cover;"
    class="item mfp-gallery cover" title="{{gallery.title}}"></a>
</div>
</ngx-slick-carousel> -->
<!-- <div class="warli"  ></div> -->
