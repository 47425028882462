<div class="abc">
  <h1 style="padding:5px;">What we provide</h1>
  <div style="width: 100%;" class="flip-body">
    <div class="flip-card reveal wow fadeInDown animated" style="visibility: visible; animation-name: fadeInDown;">
      <div class="flip-card-inner">
        <div class="flip-card-front">
          <img src="assets/Gallery/Gallery1.jpg" alt="Avatar" style="width:100%;height:300px;object-fit: cover;">
        </div>
        <div class="flip-card-back" style="padding: 20px;">
          <h1>Hotel</h1>
          <p style="justify-content: center;">
            We appreciate you staying at our hotel when you were in India. We hope you liked
            your time with us and everything that our hotel has to offer. We value the time
            you took away from your hectic schedule to stay with us.


          <!-- <p>We love that guy</p> -->
        </div>
      </div>
    </div>
    <div class="flip-card reveal wow fadeInDown animated" style="visibility: visible; animation-name: fadeInDown;">
      <div class="flip-card-inner">
        <div class="flip-card-front">
          <img src="assets/Gallery/Restaurants.jpg" alt="Avatar" style="width:100%;height:300px;object-fit: cover;">
        </div>
        <div class="flip-card-back">
          <h1>Restaurant</h1>
          <!-- <p>Architect & Engineer</p> -->
          <p></p>
        </div>
      </div>
    </div>

</div>
</div>
