import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'hotelWebsite';
}
export const APP_ID = environment.appId;
export const PROPERTY_ID = environment.propertyId;
export const API_URL_NZ = environment.nzAPIUrl;
export const API_URL_IN = environment.inAPIUrl;
export const API_URL_PROMOTION = environment.apiUrlPromotion;
export const API_URL_ADDRESS = environment.apiUrlAddress;
// export const APP_ID = environment.appId;
