import { Component, OnInit } from '@angular/core';
import { TokenStorage } from 'src/app/token.storage';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {
  // property: Property;
  reveals: any;
  windowHeight: any;
  elementTop: any;
  elementVisible: any;
  slideImage = [
    {
      imageUrl: 'assets/images/aboutimg1.png',
      title: '',
    },
    {
      imageUrl: 'assets/images/aboutimg2.png',
      title:'',
    },
    {
      imageUrl: 'assets/images/aboutimg3.png',
      title:'',
    }
  ];
  slideConfig = {
    dots: true,
    dotsData: true,
    centerMode: true,
    centerPadding: '0',
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,

    responsive: [
      {
        breakpoint: 1367,
        settings: {
          centerPadding: '0',
          dots: false
        },
      },
      {
        breakpoint: 1025,
        settings: {
          centerPadding: '0',
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          centerPadding: '0',
          slidesToShow: 1,
        },
      },
    ],
  };
  constructor(
    public token: TokenStorage,
  ) { }

  ngOnInit(): void {
    this.revealone()
    this.revealTwo()
  }

  revealone() {
    this.reveals = document.querySelectorAll('.revealLeft');

    for (var i = 0; i < this.reveals.length; i++) {
      this.windowHeight = window.innerHeight;
      this.elementTop = this.reveals[i].getBoundingClientRect().top;
      this.elementVisible = 150;

      if (this.elementTop < this.windowHeight - this.elementVisible) {
        this.reveals[i].classList.add('active');
      } else {
        this.reveals[i].classList.remove('active');
      }
    }
    window.addEventListener('scroll', this.revealone);
  }
  revealTwo() {
    this.reveals = document.querySelectorAll('.revealRight');

    for (var i = 0; i < this.reveals.length; i++) {
      this.windowHeight = window.innerHeight;
      this.elementTop = this.reveals[i].getBoundingClientRect().top;
      this.elementVisible = 150;

      if (this.elementTop < this.windowHeight - this.elementVisible) {
        this.reveals[i].classList.add('active');
      } else {
        this.reveals[i].classList.remove('active');
      }
    }
    window.addEventListener('scroll', this.revealTwo);
  }

}
