import { Address } from './address';
import { TaxDetails } from './TaxDetails';

export class Booking {
  advanceAmount: number;
  id: number;
  referenceNumber: string;
  firstName: string;
  lastName: string;
  customerDtoList: any[];
  email: string;
  mobile: string;
  checkInDateStr: string;
  checkOutDateStr: string;
  fromDate: string;
  fromDateCal: Date;
  toDate: string;
  roomType: string;
  dayTrip: boolean;
  roomPrice: number;
  airportShuttlePrice: string;
  customerId: number;
  customerAddress: Address;
  customerCompanyName: string;
  businessEmail: string;
  businessName: string;
  notes: string;
  externalBookingID: string;
  noOfRooms: number;
  noOfPersons: number;
  noOfExtraPerson: number;
  noOfChildren: number;
  noOfNights: number;
  noOfKids: number;
  noOfPets: number;
  referralCode: string;
  referredBy: number;
  roleName: string;
  externalSite: string;
  paymentId: number;
  paymentSurcharge: string;
  airportService: string;
  accomodationType: string;
  propertyReservationNumber: string;
  roomId: number;
  propertyId: number;
  available: Boolean;
  modeOfPayment: string;
  cardNumber: number;
  expMonth: number;
  expYear: number;
  cvv: number;
  currency: string;
  token: string;
  bookingAmount: number;
  payableAmount: number;
  roomName: string;
  extraChildCharge: number;
  extraPersonCharge: number;
  outstandingAmount: number;
  discountAmount: number;
  discountPercentage: number;
  businessSubtype:string;
  commissionAmount: string;
  gstAmount: number;
  taxPercentage: number;
  netAmount: number;
  totalAmount: number;
  totalServiceAmount: number;
  totalExpenseAmount: number;
  totalPaymentAmount: number;
  bookingStatus: string;
  invoiceUrl: string;
  managerContactNo: string;
  roomBooking: boolean;
  groupBooking: boolean;
  createdDate: string;
  lastModifiedDate: string;
  roomRatePlanName: string;
  planCode: string;
  noOfExtraChild: number;
  taxAmount: number;
  taxDetails: TaxDetails[];
  beforeTaxAmount: number;
  fromTime: number;
  hsnCode: any;
  includeService: boolean;
  toTime: number;
  roomTariffBeforeDiscount: number;
  totalBookingAmount: number;
  totalRoomTariffBeforeDiscount: number;
  constructor() {}
}
