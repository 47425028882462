import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ApiService, PROPERTY_ID } from 'src/app/api.service';
import { Property } from 'src/app/model/property';
import { BusinessUser } from 'src/app/model/user';
import { TokenStorage } from 'src/app/token.storage';

@Component({
  selector: 'app-home-about',
  templateUrl: './home-about.component.html',
  styleUrls: ['./home-about.component.css']
})
export class HomeAboutComponent implements OnInit {
  property: BusinessUser;
  reveals: any;
  windowHeight: any;
  elementTop: any;
  elementVisible: any;
  property1: Property;
  constructor(
    public token: TokenStorage,
    public apiService: ApiService,
  ) { }

  ngOnInit(): void {
    this.getProperty();
    this.reveal();
    this.revealTwo();
    this.revealone();
  }
  reveal() {
    this.reveals = document.querySelectorAll('.reveal');

    for (var i = 0; i < this.reveals.length; i++) {
      this.windowHeight = window.innerHeight;
      this.elementTop = this.reveals[i].getBoundingClientRect().top;
      this.elementVisible = 150;

      if (this.elementTop < this.windowHeight - this.elementVisible) {
        this.reveals[i].classList.add('active');
      } else {
        this.reveals[i].classList.remove('active');
      }
    }
    window.addEventListener('scroll', this.reveal);
  }
  revealone() {
    this.reveals = document.querySelectorAll('.revealLeft');

    for (var i = 0; i < this.reveals.length; i++) {
      this.windowHeight = window.innerHeight;
      this.elementTop = this.reveals[i].getBoundingClientRect().top;
      this.elementVisible = 150;

      if (this.elementTop < this.windowHeight - this.elementVisible) {
        this.reveals[i].classList.add('active');
      } else {
        this.reveals[i].classList.remove('active');
      }
    }
    window.addEventListener('scroll', this.revealone);
  }
  revealTwo() {
    this.reveals = document.querySelectorAll('.revealRight');

    for (var i = 0; i < this.reveals.length; i++) {
      this.windowHeight = window.innerHeight;
      this.elementTop = this.reveals[i].getBoundingClientRect().top;
      this.elementVisible = 150;

      if (this.elementTop < this.windowHeight - this.elementVisible) {
        this.reveals[i].classList.add('active');
      } else {
        this.reveals[i].classList.remove('active');
      }
    }
    window.addEventListener('scroll', this.revealTwo);
  }

  getProperty() {
    this.apiService.getPropertyDetailsByPropertyId(PROPERTY_ID).subscribe(response => {

      this.property1 = response.body;
      this.token.saveProperty(this.property);
    },
      error => {
        if (error instanceof HttpErrorResponse) {

        }
      }
    );
  }
}
