import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, PROPERTY_ID } from 'src/app/api.service';
import { DateModel } from 'src/app/model/dateModel';
import { Room } from 'src/app/model/room';
import { TokenStorage } from 'src/app/token.storage';

@Component({
  selector: 'app-rooms',
  templateUrl: './rooms.component.html',
  styleUrls: ['./rooms.component.css']
})
export class RoomsComponent implements OnInit {

  reveals: any;
  windowHeight: any;
  elementTop: any;
  elementVisible: any;
  rooms: Room[];
  dateModel: DateModel;

  slideConfig = {
    centerMode: true,
    centerPadding: '0',
    slidesToShow: 4,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1367,
        settings: {
          centerPadding: '0',
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          centerPadding: '0',
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          centerPadding: '0',
          slidesToShow: 1,
        },
      },
    ],
  };
  constructor(public apiService: ApiService,
              public router: Router,
              public token: TokenStorage) {
      this.dateModel = new DateModel();
    }

  ngOnInit() {
    this.getRoom();
    this.reveal();
  }


  reveal() {
    this.reveals = document.querySelectorAll('.reveal');

    for (var i = 0; i < this.reveals.length; i++) {
      this.windowHeight = window.innerHeight;
      this.elementTop = this.reveals[i].getBoundingClientRect().top;
      this.elementVisible = 150;

      if (this.elementTop < this.windowHeight - this.elementVisible) {
        this.reveals[i].classList.add('active');
      } else {
        this.reveals[i].classList.remove('active');
      }
    }
    window.addEventListener('scroll', this.reveal);
  }

  getRoom() {
    this.apiService.getRoomDetailsByPropertyId(PROPERTY_ID).subscribe(response => {
      this.rooms = response.body;
console.log("roomdata ",JSON.stringify(this.rooms));
    },
      error => {
        if (error instanceof HttpErrorResponse) {

        }
      }
  );
  }


}
