import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-home',
  templateUrl: './service-home.component.html',
  styleUrls: ['./service-home.component.css'],
})
export class ServiceHomeComponent implements OnInit {
  reveals: any;
  windowHeight: any;
  elementTop: any;
  elementVisible: any;
  constructor() {}

  ngOnInit(): void {
    this.reveal();
  }

  reveal() {
    this.reveals = document.querySelectorAll('.reveal');

    for (var i = 0; i < this.reveals.length; i++) {
      this.windowHeight = window.innerHeight;
      this.elementTop = this.reveals[i].getBoundingClientRect().top;
      this.elementVisible = 150;

      if (this.elementTop < this.windowHeight - this.elementVisible) {
        this.reveals[i].classList.add('active');
      } else {
        this.reveals[i].classList.remove('active');
      }
    }
    window.addEventListener('scroll', this.reveal);
  }
}
