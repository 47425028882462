<div class="overview_area secondary-bg-color ">
  <div class="about_section" style="padding: 50px;">
    <div class="row col-lg-12 " style="background-color: white; display: flex; flex-wrap:wrap; padding: 50px;">
      <div class="col-md-6 slider_animation revealLeft wow fadeInLeft animated" style="visibility: visible; animation-name: fadeInLeft;" >
        <video controls autoplay width="100%"  height="100%"class="wow fadeInLeft" >
          <source src="assets\Gallery\fotoplay20230303124340.mp4" type="video/mp4">
        </video>
        <!-- C:\Users\Asus\Documents\GitHub\hotel-wesbsites\src\assets\images\fotoplay20230112151456.mp4 -->
        <!-- C:\Users\Asus\Documents\GitHub\hotel-wesbsites\src\assets\images\fotoplay20230112151918.mp4 -->
        <!-- <img style="height: 342pxpx; width: 500px;" src="assets/images/service2.jpeg" alt="overview"> -->
      </div>
      <div class="col-md-6 revealRight wow fadeInRight animated" style="visibility: visible; animation-name: fadeInRight;">
        <!-- <p>Economical and Ecological</p> -->
        <h1>Relax In Our Hotel</h1>

        <!-- <div class="section_body"> -->
        <p>Greetings from the greatest Hotel A ONE! The personnel of Hotel A One is available round-the-clock to ensure that your stay is comfortable. All of our freshly refurbished hotel rooms come equipped with complimentary Wi-Fi, air conditioning, and geysers to make your stay even more pleasurable. Also, we offer free parking and 24(hrs) room service, so you won't need to worry about anything. We provide everything you require for a comfortable stay. You'll be glad you stayed at the A One Hotel, we're confident of it. If you're interested in booking room, please call the hotel today to reserve your room.
        </p>
        <a routerLink="/aboutus" class="common_btn2 btn_hbreffect1">about us</a>
        </div>
      <!-- </div> -->
      <!-- <div class="holder">
    <div class="hr">
        <div class="vc-od-6 vc-sd-6"> -->
      <!-- <div class="overview_img_inner">
                <div class="overview_img2 overview_img">
                    <img src="assets/images/review_img3.jpg" alt="overview">
                </div>
                <div class="overview_img3 overview_img">
                    <img src="assets/images/review_img1.jpg" alt="overview">
                </div>
            </div> -->
      <!-- <div>
              <div class="overview_img3 overview_img">
                <img src="assets/images/service2.jpeg" alt="overview">
            </div> -->
      <!-- <video width="100%" height="" controls muted >
                  <source src="assets\images\video.mp4" type="video/mp4">
                </video> -->
      <!-- </div>
        </div>
        <div class="vc-od-5 vc-od-offset-1 vc-sd-6">
            <div class="overview_text_inner align-center">
                <div class="section_title align-center">
                    <div class="section_sub_title">
                        <p>Economical and Ecological</p>
                    </div>
                    <h1>Relax In Our Hotel</h1>
                </div>
                <div class="section_body">
                    <p>Hotel A One Luxury is a modern  hotel that has affordable and budget friendly accommodation options for Family, Couple & Groups. A unique lodging facility that takes steps to reduce its carbon footprint while giving back to its local community.  We provide UV treated water in all the rooms in glass bottle keeping to minimise the plastic usage. We also attempt to digitise all our operations so that minimum paper usage in our day-day operations to save the trees. All the reservations come with complimentary breakfast options , also other delicious local cuisine meal packages that can be chosen at an extra cost. Most of our food items are being prepared from locally sourced fresh vegetables, fish & meat. We are very passionate about giving an authentic taste to the foods we prepare. A friendly and welcoming staff , who always thrive to ensure our Guests have lovely and memorable staying experiences. We call it “ A Home Away From Home” experience when the Guest  leaves us with great feedback.</p>





                    <a routerLink="/aboutus" class="common_btn2 btn_hbreffect1">about us</a>
                </div>
            </div>
        </div> -->
      <!-- </div> -->
      <!-- </div> -->
    </div>
  </div>
</div>
