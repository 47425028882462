<h1 style="text-align: center;padding-bottom: 30px;">Near By places</h1>


<div class="container">
  <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner reveal wow fadeInDown animated" style="visibility: visible; animation-name: fadeInDown;">
    <div class="carousel-item active" >
      <div class="cards-wrapper">
      <div class="card"*ngFor="let item of data">
        <img src="{{item.url}}" class="card-img-top" alt="..." height="300px">

      </div>

    </div>
    </div>
    <div class="carousel-item">
      <div class="cards-wrapper">
        <div class="card" *ngFor="let item of data">
          <img src="{{item.url}}" class="card-img-top" alt="..." height="300px">
        </div>
      </div>
    </div>

  </div>
  <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>
</div>

