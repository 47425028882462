<div class="cmn_banner_area banner_type14 bnr_overlay2">
  <div class="holder">
      <div class="hr">
          <div class="vc">
              <div class="section_title align-center">
                  <div class="section_sub_title">
                      <p>Refund Policy</p>
                  </div>
              </div>
          </div>
      </div>
      <div class="hr">
          <div class="vc">
              <div class="bradecrumbe">
                  <ul class="breadcrumb justify-content-center justify-content-cd--center">
                      <li>
                          <a routerLink="/home">
                              Home
                          </a>
                      </li>
                      <li class="active">
                          Refund Policy
                      </li>
                  </ul>
              </div>
          </div>
      </div>
  </div>
</div>

<div class="container card " style="margin:30px;padding: 20px;">
  <div  style="text-align: center;" >

      <!-- Container -->
<li><span>If you cancel your reservation or booking within the specified time frame amount non refundable.</span></li>

    </div>


  </div>








