import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService, PROPERTY_ID } from 'src/app/api.service';
import { Property } from 'src/app/model/property';
import { BusinessUser } from 'src/app/model/user';
import { TokenStorage } from 'src/app/token.storage';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {
  modalImage = "";
  modalTitle = "";
    closeResult = "";
  galleryImage = [
    {
      imageURL: 'assets/Gallery/Gallery1.jpg',
      title: 'Room',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery2.jpg',
      title: 'Room',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery3.jpg',
      title: 'Room',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery4.jpg',
      title: 'Room',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery5.jpg',
      title: 'Room',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery6.jpg',
      title: 'Room',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery7.jpg',
      title: 'Room',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery8.jpg',
      title: 'Room',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery9.jpg',
      title: 'Room',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery20.jpg',
      title: 'Room',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery21.jpg',
      title: 'Room',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery22.jpg',
      title: 'Bathroom',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery24.jpg',
      title: 'Rooms',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery26.jpg',
      title: 'Room',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery27.jpg',
      title: 'Room',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery28.jpg',
      title: 'Room',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery29.jpg',
      title: 'Room',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery30.jpg',
      title: 'Room',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery31.jpg',
      title: 'Room',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery32.jpg',
      title: 'Room',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery33.jpg',
      title: 'Room',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery34.jpg',
      title: 'Room',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery36.jpg',
      title: 'Room',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery37.jpg',
      title: 'Room',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery39.jpg',
      title: 'Room',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery40.jpg',
      title: 'Room',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery42.jpg',
      title: 'Room',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery43.jpg',
      title: 'Room',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery44.jpg',
      title: 'Room',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery45.jpg',
      title: 'Room',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery46.jpg',
      title: 'Room',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery47.jpg',
      title: 'Room',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery48.jpg',
      title: 'Room',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery49.jpg',
      title: 'Room',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery50.jpg',
      title: 'Room',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery52.jpg',
      title: 'Room',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery53.jpg',
      title: 'Room',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery54.jpg',
      title: 'Room',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery55.jpg',
      title: 'Room',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery56.jpg',
      title: 'Room',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery57.jpg',
      title: 'Room',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery10.jpg',
      title: 'Bathroom',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery11.jpg',
      title: 'Bathroom',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery12.jpg',
      title: 'Bathroom',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery13.jpg',
      title: 'Bathroom',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery14.jpg',
      title: 'Bathroom',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery15.jpg',
      title: 'Bathroom',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery16.jpg',
      title: 'Bathroom',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery17.jpg',
      title: 'Bathroom',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery18.jpg',
      title: 'Bathroom',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery19.jpg',
      title: 'Bathroom',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery23.jpg',
      title: 'Bathroom',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery25.jpg',
      title: 'Bathroom',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery35.jpg',
      title: 'Bathroom',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery38.jpg',
      title: 'Bathroom',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery41.jpg',
      title: 'Bathroom',
      class: 'rooms'
    },
    {
      imageURL: 'assets/Gallery/Gallery51.jpg',
      title: 'Bathroom',
      class: 'rooms'
    },
    // {
    //   imageURL: 'assets/images/gallery_img13.jpg',
    //   title: 'Hotel'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img1.jpg',
    //   title: 'Hotel'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img14.jpg',
    //   title: 'Hotel'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img15.jpg',
    //   title: 'Hotel'
    // }, {
    //   imageURL: 'assets/images/gallery_img16.jpg',
    //   title: 'Hotel'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img17.jpg',
    //   title: 'Hotel'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img18.jpg',
    //   title: 'Hotel'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img19.jpg',
    //   title: 'Hotel'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img20.jpg',
    //   title: 'Hotel'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img21.jpg',
    //   title: 'Hotel'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img22.jpg',
    //   title: 'Hotel'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img23.jpg',
    //   title: 'Hotel'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img24.jpg',
    //   title: 'Hotel'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img25.jpg',
    //   title: 'Hotel'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img26.jpg',
    //   title: 'Hotel'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img27.jpg',
    //   title: 'Hotel'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img28.jpg',
    //   title: 'Hotel'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img29.jpg',
    //   title: 'Hotel'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img30.jpg',
    //   title: 'Hotel'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img31.jpg',
    //   title: 'Hotel'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img32.jpg',
    //   title: 'Hotel'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img33.jpg',
    //   title: 'Hotel'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img34.jpg',
    //   title: 'Hotel'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img35.jpg',
    //   title: 'Hotel'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img36.jpg',
    //   title: 'Hotel'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img37.jpg',
    //   title: 'Hotel'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img38.jpg',
    //   title: 'Hotel'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img39.jpg',
    //   title: 'Hotel'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img40.jpg',
    //   title: 'Hotel'
    // },
    // {
    //   imageURL: 'assets/images/gallery_img41.jpg',
    //   title: 'Hotel'
    // },
  ]
  property: BusinessUser;
  property1: Property;
  constructor(
    private router: Router,
    private token: TokenStorage,
    private apiService: ApiService,
    private modalService: NgbModal,
  ) {
    this.property = new BusinessUser();
    if (this.token.getPropertyData() !== null) {
      this.property = this.token.getPropertyData();
    } else {
      this.getProperty();
    }
  }

  ngOnInit(): void {
  }
  modalSlideConfig = {
    centerMode: true,
    centerPadding: "0%",
    slidesToShow: 1,
    dots: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1367,
        settings: {
          centerPadding: "0%",
        },
      },
      {
        breakpoint: 1025,
        settings: {
          centerPadding: "0",
        },
      },
      {
        breakpoint: 767,
        settings: {
          centerPadding: "0",
          slidesToShow: 1,
        },
      },
    ],
  };
  getProperty() {
    this.apiService.getPropertyDetailsByPropertyId(PROPERTY_ID).subscribe(response => {

      this.property1 = response.body;
      this.token.saveProperty(this.property);
    },
      error => {
        if (error instanceof HttpErrorResponse) {

        }
      }
    );
  }
// getGallery(){}


}
