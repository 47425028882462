<div class="main-wrapper">
  <!--Title Bar -->
  <!-- <title-bar title="Booking Details" subtitle="Here is your final information"></title-bar> -->
 <div>
  <div id="print-section">
    <!--Your html stuff that you want to print-->
  </div>
  <!-- <button class="btn" printSectionId="print-section" ngxPrint>print</button>  -->

 </div>
  <!-- Container -->
  <div class="content">
    <div class="cmn_banner_area banner_type13 bnr_overlay2">
      <div class="holder">
        <div class="hr">
          <div class="vc">
            <div class="section_title align-center">
              <div class="section_sub_title">
                <p>Booking Complete</p>
              </div>
            </div>
          </div>
        </div>
        <div class="hr">
          <div class="vc">
            <div class="bradecrumbe">
              <ul class="breadcrumb justify-content-center justify-content-cd--center">
                <li>
                  <a routerLink="/home"> Home </a>
                </li>
                <li class="active">Booking Complete</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" style="margin-top: 20px;margin-bottom: 20px;">
      <div class="row">
        <!-- Sidebar -->
        <div class="col-12" *ngIf="(this.payment.status === 'NotPaid' && this.payment.failureCode === null) && !bookingConfirmed" >
          <h2 style="color: red;font-weight: 700;">ERROR: {{payment.failureCode}}</h2>
          <p>{{payment.failureMessage}}</p>
          <li><b>Order ID:</b> {{ paymentorderid }}</li>
          <h5 style="color: red;">Oops!! Seems your payment is not successful , please contact with the property owner for more details .</h5>
          <button class="btn " type="button" (click)="onGoHome()" style="background-color: #ffcf04;">Go Back</button>
      </div>
        <div class="col-lg-12 col-md-12">


          <div *ngIf="bookingConfirmed">
            <h4 class="mb-3 mt-4"  style="text-align: center;color: #835f74;
            font-size: 30px;
            font-family: Roboto Slab;
            font-weight: 700;">Thank you for your booking!</h4>

            <div class="row" style="margin-bottom: 20px;">

              <div class="col-lg-4 col-md-4">
                <!-- Booking Summary -->
                <div class="order-summary-widget">
                  <div class="listing-item">
                    <img *ngIf="businessUser.logoUrl" src="{{businessUser.logoUrl}}" alt="" width="200px" height="200px">

                    <img *ngIf="!businessUser.logoUrl" src="assets/images/app-logo.png" alt="" width="100%">
                    <div class="listing-item-content pt-3">
                      <!-- <div>
                <span class="pull-left badge badge-pill list-banner badge-success text-uppercase">{{businessUser.businessType}}</span>
              </div> -->
                      <h3>{{businessUser.name}}</h3>
                      <span><small *ngIf="businessUser.address">
                          <p class="description">
                            {{businessUser.address.streetNumber? businessUser.address.streetNumber + ', ' : '' }}
                            {{businessUser.address.streetName? businessUser.address.streetName + ', ' : '' }}
                            {{businessUser.address.suburb? businessUser.address.suburb + ', ' : '' }}
                            {{businessUser.address.locality? businessUser.address.locality + ', ' : '' }}
                            {{businessUser.address.city? businessUser.address.city + ', ' : '' }}
                            {{ businessUser.address.country? businessUser.address.country : '' }}
                          </p>
                        </small></span>
                    </div>
                  </div>
                </div>
                <!-- <div class="bg-secondary p-4">
                  <h4><i class="fa text-primary fa-calendar-check-o"></i> Booking Summary</h4>
                  <ul class="summery">
                    <li><b>Date: </b> <span> {{booking.fromDate | date:'EEEE, MMM d, y'}} - {{booking.toDate | date:'EEEE, MMM d, y'}}</span></li>
                    <li><b>Number of Night(s): </b> <span> {{timeDifferenceInDays}}</span></li>
                    <li><b>Room Type: </b><span>{{booking.roomType}}</span></li>
                    <li><b>No Of Person(s): </b><span>{{booking.noOfPersons}}</span></li>
                    <li><b>No Of Room(s): </b><span>{{booking.noOfRooms}}</span></li>
                    <li><b>Room Price: </b><span>{{booking.roomPrice}} </span>
                    </li>
                    <li><b>Net Amount: </b> <span>{{booking.netAmount | currency: businessUser.localCurrency.toUpperCase():'symbol':'1.2-2' }}
                        </span></li>
                    <li><b>Tax Amount ({{this.taxPercentage}}%): </b>
                      <span>{{booking.totalAmount - booking.netAmount| number : '1.2-2' }}</span></li>
                    <li>
                      <h6>Total Amount: <span>{{booking.totalAmount | currency: businessUser.localCurrency.toUpperCase():'symbol':'1.2-2' }}
                          </span></h6>
                    </li>

                  </ul>
                </div> -->
                <!-- Booking Summary / End -->
              </div>
              <!-- Content -->
              <div class="col-lg-8 col-md-8 padding-right-30">
                <ul class="summery">
<li><b>Order ID:</b> {{ paymentorderid }}</li>
                  <li><b>Reservation No:</b> {{ booking.propertyReservationNumber }}</li>
                  <li><b>Full guest name: </b> {{ booking.firstName }} {{booking.lastName}}</li>
                  <li><b>Email: </b> <span> {{booking.email}}</span></li>
                  <li><b>Phone: </b><span> {{booking.mobile}}</span></li>
                  <li><b>Selected Room: </b><span> {{booking.roomName}}</span></li>
                  <li><b>Room Price: </b><span>
                      {{booking.roomPrice | currency: currency:'symbol':'1.2-2' }} </span>
                  </li>
                  <li><b>No Of Person(s): </b><span> {{booking.noOfPersons}}</span></li>


                  <!-- <li><b>Net Amount: </b> <span>
                      {{ booking.totalAmount - booking.taxAmount + booking.discountAmount | currency: currency:'symbol':'1.2-2' }}
                    </span></li>
                  <li><b>Tax Amount ({{this.taxPercentage}}%): </b>
                    <span>
                      {{ booking.taxAmount | currency: currency:'symbol':'1.2-2' }}</span></li> -->
                  <li *ngIf="discountPercentage"><b>Discount: </b>{{booking.discountPercentage}}%</li>
                  <li>
                  <li *ngIf="discountPercentage"><b>Discount Amount:
                    </b>{{booking.discountAmount | currency: currency:'symbol':'1.2-2'}}</li>
                  <li>
                    <h5>Total Amount: <span>
                        {{booking.totalAmount | currency: currency:'symbol':'1.2-2' }} </span>
                    </h5>
                  </li>
                  <li>
                    <h5>Advance Amount: <span>
                        {{booking.advanceAmount | currency: currency:'symbol':'1.2-2' }} </span>
                    </h5>
                  </li>
                  <li><b>Special Notes:</b> <span> {{booking.notes}}</span></li>
                  <!-- <li><b>Payment method:</b> <span> {{payment.paymentMode}}</span></li>

                  <li >
                    <h5>Paid amount:<span>
                        {{payment.transactionChargeAmount | currency: currency:'symbol':'1.2-2' }}
                      </span></h5>
                  </li>
                  <li >
                    <h5>Payment Status:<span> Not Paid </span></h5>
                  </li> -->

                </ul>
              </div>
              <div class="col-md-12">
                <button class="btn " type="button" (click)="onGoHome()" style="background-color: #ffcf04;">Go Back</button>

              </div>

            </div>
          </div>
          <div *ngIf="enquirySent == true">
            <h4 class="mb-3 mt-4" >Your Payment was not successful! But an enquiry for your booking is sent!</h4>

            <div class="row" >

              <div class="col-lg-4 col-md-4">
                <!-- Booking Summary -->
                <div class="order-summary-widget">
                  <div class="listing-item">
                    <img *ngIf="businessUser.logoUrl" src="{{businessUser.logoUrl}}" alt="" width="100%" height="auto">

                    <img *ngIf="!businessUser.logoUrl" src="assets/images/app-logo.png" alt="" width="100%">
                    <div class="listing-item-content pt-3">
                      <!-- <div>
                <span class="pull-left badge badge-pill list-banner badge-success text-uppercase">{{businessUser.businessType}}</span>
              </div> -->
                      <h3>{{businessUser.name}}</h3>
                      <span><small *ngIf="businessUser.address">
                          <p class="description">
                            {{businessUser.address.streetNumber? businessUser.address.streetNumber + ', ' : '' }}
                            {{businessUser.address.streetName? businessUser.address.streetName + ', ' : '' }}
                            {{businessUser.address.suburb? businessUser.address.suburb + ', ' : '' }}
                            {{businessUser.address.locality? businessUser.address.locality + ', ' : '' }}
                            {{businessUser.address.city? businessUser.address.city + ', ' : '' }}
                            {{ businessUser.address.country? businessUser.address.country : '' }}
                          </p>
                        </small></span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Content -->
              <div class="col-lg-8 col-md-8 padding-right-30">
                <ul class="summery"  >

                  <!-- <li><b>Enquiry No:</b> {{ enquiryForm.id }}</li> -->
                  <li><b>Full guest name: </b> {{ enquiryForm.firstName }} {{enquiryForm.lastName}}</li>
                  <li><b>Email: </b> <span> {{enquiryForm.email}}</span></li>
                  <li><b>Phone: </b><span> {{enquiryForm.mobile}}</span></li>
                  <li><b>Selected Room: </b><span> {{enquiryForm.roomName}}</span></li>
                  <li><b>Room Price: </b><span>
                      {{enquiryForm.roomPrice | currency: currency:'symbol':'1.2-2' }} </span>
                  </li>
                  <li><b>No Of Person(s): </b><span> {{enquiryForm.noOfPersons}}</span></li>

                  </ul>
              </div>
            </div>

          </div>
          <!-- <ngb-alert class="mt-3" *ngIf="showAlert" [type]="alertType" (close)="showAlert = false">
            <strong>{{headerTitle}}</strong> {{bodyMessage}}</ngb-alert> -->

        </div>

      </div>
    </div>
  </div>
</div>
