<div class="cmn_banner_area banner_type14 bnr_overlay2">
  <div class="holder">
      <div class="hr">
          <div class="vc">
              <div class="section_title align-center">
                  <div class="section_sub_title">
                      <p>Gallery</p>
                  </div>
              </div>
          </div>
      </div>
      <div class="hr">
          <div class="vc">
              <div class="bradecrumbe">
                  <ul class="breadcrumb justify-content-center justify-content-cd--center">
                      <li>
                          <a routerLink="/home">
                              Home
                          </a>
                      </li>
                      <li class="active">
                          Gallery
                      </li>
                  </ul>
              </div>
          </div>
      </div>
  </div>
</div>


<div class="gallery_area_type1 ptb-70">
  <div class="holder">
      <div class="hr">
          <div class="vc-sd-12">
              <div class="gallery_filter align-center">
                  <!-- <ul class="filter" id="grid">
                      <li class="active" data-filter="*">
                          <span class="common_btn">All Photos</span>
                      </li>
                      <li data-filter=".rooms">
                          <span class="common_btn">Rooms</span>
                      </li>
                      <li data-filter=".restaurant" class="">
                          <span class="common_btn">Restaurant & Club</span>
                      </li>
                      <li data-filter=".hotel">
                          <span class="common_btn">Hotel</span>
                      </li>
                  </ul> -->
              </div>
          </div>
      </div>
      <div class="hr">
          <div class="vc-sd-12">
              <div class="gallery_item align-center">
                  <div class=" gallery_item1 row"  data-toggle="modal" data-target="#largeModal">

                      <div *ngFor="let gallery of galleryImage" class="col-3 grid-item width-1-4 {{gallery.class}}" >


                        <div class="item_content" >
                              <div class="single_grid_item" >
                                  <img class="mobile_view_imgsize"src="{{gallery.imageURL}}" alt="gallery" >
                                  <a routerLink="{{gallery.imageURL}}" class="singel_item_hover_content restaurant_menu_image" data-gall="smg">
                                      <h5>{{gallery.title}}</h5>

                                  </a>
                              </div>
                          </div>

                      </div>


                  </div>
              </div>
          </div>
      </div>
  </div>
   <!-- modal -->
   <div class="modal fade" id="largeModal" tabindex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="false">
    <div class="modal-dialog modal-lg">
      <div class="modal-content modal_class">
        <div class="modal-body">
           <!-- carousel -->
          <div
               id='carouselExampleIndicators'
               class='carousel slide'
               data-ride='carousel'
               >
            <ol class='carousel-indicators'>
              <li
                  data-target='#carouselExampleIndicators'
                  data-slide-to='0'
                  class='active'
                  ></li>
              <li
                  data-target='#carouselExampleIndicators'
                  data-slide-to='1'
                  ></li>
              <li
                  data-target='#carouselExampleIndicators'
                  data-slide-to='2'
                  ></li>
                  <li
                  data-target='#carouselExampleIndicators'
                  data-slide-to='3'
                  ></li>
                  <li
                  data-target='#carouselExampleIndicators'
                  data-slide-to='4'
                  ></li>
                  <li
                  data-target='#carouselExampleIndicators'
                  data-slide-to='5'
                  ></li>
            </ol>
            <div class='carousel-inner'>
              <div class='carousel-item active'>
                <img class='img-size' src='assets/images/gallery1.png' alt='First slide' />
              </div>
              <div class='carousel-item'>
                <img class='img-size' src='assets/images/gallery2.png' alt='Second slide' />
              </div>
              <div class='carousel-item'>
                <img class='img-size' src='assets/images/gallery3.png' alt='Second slide' />
              </div>
              <div class='carousel-item'>
                <img class='img-size' src='assets/images/gallery4.png' alt='Third slide' />
              </div>
              <div class='carousel-item'>
                <img class='img-size' src='assets/images/gallery5.jpg' alt='Third slide' />
              </div>
              <div class='carousel-item'>
                <img class='img-size' src='assets/images/aboutimage.png' alt='Third slide' />
              </div>
            </div>
            <a
               class='carousel-control-prev'
               href='#carouselExampleIndicators'
               role='button'
               data-slide='prev'
               >
              <span class='carousel-control-prev-icon'
                    aria-hidden='true'
                    ></span>
              <span class='sr-only'>Previous</span>
            </a>
            <a
               class='carousel-control-next'
               href='#carouselExampleIndicators'
               role='button'
               data-slide='next'
               >
              <span
                    class='carousel-control-next-icon'
                    aria-hidden='true'
                    ></span>
              <span class='sr-only'>Next</span>
            </a>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>

