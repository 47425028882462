import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookingComponent } from './booking.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
// import { NewBookingComponent } from './new-booking/new-booking.component';
import { ChooseRoomComponent } from './choose-room/choose-room.component';
import { CompleteComponent } from './complete/complete.component';
import { BookingDetailsComponent } from './booking-details/booking-details.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { NewBookingComponent } from './new-booking/new-booking.component';
import { CheckoutHdfcComponent } from './checkout-hdfc/checkout-hdfc.component';
import { BookingCompleteComponent } from './booking-complete/booking-complete.component';
const routes: Routes = [
  { path: 'booking/choose', component: ChooseRoomComponent },
  { path: 'booking/booking', component: NewBookingComponent },
  { path: 'booking/payment', component: CheckoutComponent },
  { path: 'booking/complete', component: CompleteComponent },
  { path: 'booking/checkout-hdfc', component: CheckoutHdfcComponent },
  { path: 'booking/booking-complete', component: BookingCompleteComponent },
  { path: 'booking/details/:id/:email', component: BookingDetailsComponent },
  { path: '',  redirectTo: 'booking/choose', pathMatch: 'full' },
];
@NgModule({
  imports: [
    NgbModule,
    CommonModule,

    FormsModule,
    Ng2TelInputModule,
    ReactiveFormsModule,
    SlickCarouselModule,
    RouterModule.forChild(routes),
  ],
  declarations: [
    // BookingComponent,
    CheckoutComponent,
    NewBookingComponent,
BookingCompleteComponent,
CheckoutHdfcComponent,
    ChooseRoomComponent,
    CompleteComponent,
    BookingDetailsComponent,
  ],
})
export class BookingModule {}
