// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appId: 'HotelWebsite',
  // apiUrl: 'https://testapi.bookonelocal.co.nz/api-bookone',
  // apiUrlAddress: 'https://testapi.bookonelocal.co.nz/api-address',
  // apiUrlPromotion: 'https://testapi.bookonelocal.co.nz/promotion-api',
  // propertyId: 443,
  // country: 'nz',
  apiLms: 'https://api.bookonelocal.in/api-lms',
  inAPIUrl: 'https://api.bookonelocal.in/api-bookone',
  nzAPIUrl: 'https://api.bookonelocal.in/api-bookone',
  apiUrl: 'https://api.bookonelocal.in/api-bookone',
  apiUrlAddress: 'https://api.bookonelocal.in/api-address',
  apiUrlPromotion: 'https://api.bookonelocal.in/promotion-api',
  propertyId: 830,
  country: 'in',
  // nzAPIUrl: 'https://api.bookonelocal.in/api-bookone',
  // inAPIUrl: 'https://api.bookonelocal.in/api-bookone',
  googleKey: 'AIzaSyAYT8pe61MUbk27eiYi9LnnPhwo031Ye7w',
  callbackUrl: 'https://hotelaoneluxury.com/#/booking/booking-complete?ReferenceNumber='
  // googleKey: 'AIzaSyCYc537bQom7ajFpWE5sQaVyz1SQa9_tuY'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
