<div class="navbar_inner_area">
  <nav class="navigation-area overlay sticky-navbar fadeInUp" [ngClass]="{'sticky': sticky}" (scroll)="onElementScroll()" data-sticky-offset="50">
    <div class="holder">
      <div class="menu_toggle" (click)="menuToggle()">
        <i class="fa fa-align-left"></i>
      </div>
      <a routerLink="/" class="brand-logo"  style="width:100px">
        <!-- <img src="assets/images/logo.png" alt="logo" class="logo"> -->
        <img src="{{property.logoUrl}}" class="logo" alt="" style="border-radius: 50%;"  >
      </a>
      <div class="navbar-mobile" [ngClass]="{'active': mobileActive}">
        <ul class="navbar-nav single-item-right">
          <li class="">
            <a routerLink="/"  (click)="menuToggle()">
              Home
            </a>

          </li>
          <li>
            <a routerLink="/all-rooms"  (click)="menuToggle()">
              Rooms
            </a>
            <!-- <ul class="DropDown">
                          <li>
                              <a href="">
                                  All Rooms
                              </a>
                          </li>
                          <li>
                              <a href="room2.php">
                                  room-2
                              </a>
                          </li>
                          <li>
                              <a href="room-details.php">
                                  Room details
                              </a>
                          </li>
                      </ul> -->
          </li>
          <!-- <li>
            <a routerLink="/menu/Feathers-Hospitality-Flora-Eco-Stay">
              Menu
            </a>
          </li> -->
          <li>
            <a routerLink="/services"  (click)="menuToggle()">
              Service
            </a>
          </li>
          <li>
            <a routerLink="/gallery"  (click)="menuToggle()">Gallery</a>
          </li>
          <li>
            <a routerLink="/aboutus"  (click)="menuToggle()">
              About Us
            </a>
          </li>
          <li>
            <a routerLink="/contact"  (click)="menuToggle()">
              Contact
            </a>
          </li>
          <li>
              <a href="#/booking/choose" class=" common_btn1 btn_hbreffect"  (click)="menuToggle()" style="margin-top: 8px; padding: 15px;">book now</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>
