import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, PROPERTY_ID } from 'src/app/api.service';
import { Property } from 'src/app/model/property';
import { BusinessUser } from 'src/app/model/user';
import { TokenStorage } from 'src/app/token.storage';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  property: BusinessUser;
  property1: Property;
  constructor(
    public router: Router,
   public token: TokenStorage,
   public apiService: ApiService
  ) {
    this.property = new BusinessUser();
    if (this.token.getPropertyData() !== null && this.token.getPropertyData() !== undefined) {
      this.property = this.token.getPropertyData();
    } else {
      this.getProperty();
    }

  }

  ngOnInit(): void {
    if (this.token.getPropertyData() !== null) {
      this.property = this.token.getPropertyData();
    } else {
      this.getProperty();
    }
  }
  getProperty() {
    this.apiService.getPropertyDetailsByPropertyId(PROPERTY_ID).subscribe(response => {

      this.property = response.body;
      this.token.saveProperty(this.property);
    },
      error => {
        if (error instanceof HttpErrorResponse) {

        }
      }
    );
  }
  scroll_top(){
    document.documentElement.scrollTop = 0;
  }

}
