<div class="cmn_banner_area banner_type14 bnr_overlay2">
  <div class="holder">
      <div class="hr">
          <div class="vc">
              <div class="section_title align-center">
                  <div class="section_sub_title">
                      <p>Terms & Conditions</p>
                  </div>
              </div>
          </div>
      </div>
      <div class="hr">
          <div class="vc">
              <div class="bradecrumbe">
                  <ul class="breadcrumb justify-content-center justify-content-cd--center">
                      <li>
                          <a routerLink="/home">
                              Home
                          </a>
                      </li>
                      <li class="active">
                          Terms & Conditions
                      </li>
                  </ul>
              </div>
          </div>
      </div>
  </div>
</div>

<div class="container card " >
  <div  style="margin: 20px;">
  <strong> Guest Profile: </strong>

<li><span> couples are not allowed</span></li>
<li><span>Guests below 18 years of age are allowed</span></li>
<li><span>Groups with only male guests are not allowed at this property</span></li>
<strong>ID Proof Related :</strong>
<li><span>Passport, Aadhar, Govt. ID and Driving License are accepted as ID proof(s)</span></li>
<li><span>Local ids not allowed</span></li>

<strong>Food Arrangement:</strong>

  <li><span>Non veg food is allowed</span></li>
<li><span>Outside food is not allowed</span></li>
<li><span>Food delivery service is not available at the property</span></li>

<strong>Smoking/Alcohol consumption Rules :</strong>
<li><span>
  Smoking within the premises is not allowed</span></li>
<li><span>There are no restrictions on alcohol consumption.</span></li>
<strong>Pet(s) Related:</strong>

<li><span>Pets are not allowed.</span></li>
<li><span>There are no pets living on the property</span></li>
<strong>Property Accessibility :</strong>

<li><span>This property is accessible to guests who use a wheelchair. Guests are requested to carry their own wheelchair.
  Other Rules
  </span></li>
<li>Does not allow private parties or events</li>
<li>Visitors are allowed</li>
<strong>Child & Extra Bed Policy</strong>

<li><span>An extra bed will be provided to accommodate any child included in the booking for a charge mentioned below. (Subject to availability)
  INR 400 will be charged for an extra mattress per child. (To be paid at the property).</span></li>
<li><span>An extra bed will be provided to accommodate any additional guest included in the booking for a charge mentioned below. (Subject to availability)
  INR 400 will be charged for an extra mattress per guest. (To be paid at the property).</span></li>
  </div>
</div>
