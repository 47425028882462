import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privery-policy',
  templateUrl: './privery-policy.component.html',
  styleUrls: ['./privery-policy.component.css']
})
export class PriveryPolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
