import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { debug } from 'console';
import { ApiService } from 'src/app/api.service';
import { Property } from 'src/app/model/property';
import { BusinessUser } from 'src/app/model/user';
import { TokenStorage } from 'src/app/token.storage';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  property: BusinessUser;
  sticky:boolean = false;
  property1: any;
  constructor(
   public router: Router,
  public token: TokenStorage,
   public apiService: ApiService
  ) {
    this.property = new BusinessUser();
    if (this.token?.getPropertyData() !== null && this.token?.getPropertyData() !== undefined) {
      this.property = this.token?.getPropertyData();
    } else {
      this.getProperty();
    }
  }

  ngOnInit(): void {
  }
  onElementScroll(event){
    console.log(event);
    if(this.sticky === true){
      this.sticky = false;

    } else{
      this.sticky = true;
    }
  }
  getProperty() {
//  debugger
  this.apiService.getPropertyDetailsByPropertyId(830).subscribe(response => {

      this.property = response.body;
      console.log("propertydata" +JSON.stringify(this.property))
      this.token.saveProperty(this.property);
    },
      error => {
        if (error instanceof HttpErrorResponse) {

        }
      }
    );
  }
}
