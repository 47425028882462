<div class="banner_area_type3 bnr_overlay2">
    <div class="holder">
        <div class="hr">
            <div class="vc">
                <div class="banner_section_title align-center">
                    <div class="section_sub_title">
                        <p>about us</p>
                    </div>
                    <h1>A Home Away From Home</h1>
                </div>
            </div>
        </div>
        <div class="hr">
            <div class="vc">
                <div class="bradecrumbe">
                    <ul class="breadcrumb justify-content-center justify-content-cd--center">
                        <li>
                            <a routerLink='/'>
                                Home
                            </a>
                        </li>
                        <li class="active">
                            About Us
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="holder pt-5">
    <div class="row">
        <div class="col-md-6 mb-2 margin_top revealLeft wow fadeInLeft animated" style="visibility: visible; animation-name: fadeInLeft;" >
            <ngx-slick-carousel
            class="camera_wrap carousel listing-slider mfp-gallery-container margin-bottom-0 hero_slider"
            #slickModal="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem *ngFor="let gallery of slideImage"
                class="slide camera_src camerastarted ">
                <img class=" " src="{{gallery.imageUrl}}"  alt="First slide">
                <!-- <a [ngStyle]="{'background-image': 'url(' + gallery.imageUrl + ')'}"
                    style="background-size: cover;" class="item mfp-gallery cover"
                    title="{{gallery.title}}"></a> -->
            </div>
        </ngx-slick-carousel>
        </div>

        <div class="col-md-6 mb-5 revealRight wow fadeInRight animated" style="visibility: visible; animation-name: fadeInRight;">
            <div class="overview_text_inner align-center">
                <div class="section_title align-center">

                    <h1>Hotel A One Luxury Puri, Odisha </h1>
                </div>
                <div class="section_body">
                    <p>Greetings from the greatest Hotel A ONE! The personnel of Hotel A One is available round-the-clock to ensure that your stay is comfortable. All of our freshly refurbished hotel rooms come equipped with complimentary Wi-Fi, air conditioning, and geysers to make your stay even more pleasurable. Also, we offer free parking and 24(hrs) room service, so you won't need to worry about anything. We provide everything you require for a comfortable stay. You'll be glad you stayed at the A One Hotel, we're confident of it. If you're interested in booking room, please call the hotel today to reserve your room.
                    </p>
                </div>
            </div>
        </div>


    </div>
    </div>
